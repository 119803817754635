import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter, withDisabledInitialNavigation } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideBcfAppId, provideBcfReleaseVersion } from '@bcf-v2-configurators/tokens/app/provider';
import { incorrectCsrfTokenRetryInterceptor } from '@bcf-v2-shared/misc/interceptors/incorrect-csrf-token-retry-interceptor';
import { status0RetryInterceptor } from '@bcf-v2-shared/misc/interceptors/status0-retry-interceptor';
import { buildSettings } from '@env/build-settings';
import { releaseVersion } from '@env/release-version';
import { envAppProvidersBrowser } from '../../../mobile-web/src/env-app-providers.browser';
import { bcfAppId } from '../bcf-app-id';
import { envAppProvidersTwa } from '../env-app-providers.twa';
import { appBrowserProviders } from './app.providers';
import { routes } from './app.routes';

const appRuntimeId: number = new Date().getTime();
const worker: Worker = new Worker(new URL(/* webpackChunkName: "app-worker" */ '../worker', import.meta.url));

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes, withDisabledInitialNavigation()),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
      withInterceptors([status0RetryInterceptor, incorrectCsrfTokenRetryInterceptor])
    ),
    // sports-mobile-ngsw-worker.js check in gae deploy script
    provideServiceWorker('ngsw-worker.js', {
      enabled: !buildSettings.isDevServer && buildSettings.environment === 'prod'
    }),
    provideBcfAppId(bcfAppId),
    provideBcfReleaseVersion(releaseVersion),
    ...envAppProvidersBrowser(worker, appRuntimeId, 'mobile-web-twa'),
    ...envAppProvidersTwa(),
    ...appBrowserProviders
  ]
};
